@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;500;600&display=swap');


/* https://css-tricks.com/almanac/properties/a/animation/ */
/* Animations  */
@keyframes btn_color_out {
  0% {
    color: white;
    background: #0187ff;
  }
  100% {
    color: #f2f2ff;
    background: #1f45a3;
  }
}


*{
  padding: 0;
  margin: 0;
}




/* Remove Arrows/Spinners */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}

body {
  /* font-family: 'Montserrat', sans-serif; */
  font-family: 'Arial Black', Gadget, sans-serif;
  font-weight: 400;  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



button{
  border: none;
  padding: 10px 15px;
  outline: none;
  border-radius: 32px;
  min-height: 50px;
  min-width: 250px;
  /* - button height 50px */
  font-size: 1.4rem;
  font-weight: 700;
}


input, textarea{
  /* padding: 13px; */
  /* border-radius: 5px; */
  /* border: 2px solid #1f45a3; */
  background-color: #f2f2ff;
  /* color: #1f45a3; */
  outline: none;
}
input{
  min-height: 40px;
}
p{
  font-size: 1.4rem;
}
p span{
  color: #ffc727;
}
h1{
  font-size: 2.2rem;
  font-weight: 800;
}
h2{
  font-size: 2.2rem;
}




label{
  margin-bottom: 5px;
  font-size: 1.4rem;
}



.container{
  width: 80%;
  margin-left: 10%;
}

.row{
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.btn{
  color: #f2f2ff;
  /* background: linear-gradient(180deg,#0d41d5 17.83%,#0187ff); */
  background: #0187ff;
  
}

.btn:hover{
  /* background: linear-gradient(180deg,#0d41d5 17.83%,#0187ff); */
  /* color: #1f45a3; */
  border: 2px solid #1f45a3;
  animation: btn_color_out 2s infinite;
}

.btn-outline{
  color:#1f45a3;
  /* background: linear-gradient(180deg,#0d41d5 17.83%,#0187ff); */
  background: #f2f2ff;
}

.btn-outline:hover{
  background:#1f45a3;
  /* background: linear-gradient(180deg,#0d41d5 17.83%,#0187ff); */
  color: #f2f2ff;
  /* animation: name duration timing-function delay iteration-count direction fill-mode; */
}


.input-group{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-top: 10px;
}

.form-control{
  /* width: 100%; */
  padding: 5px;
}


.mt-5{
  margin-top: 3rem !important;
}

.mt-4{
  margin-top: 2.5rem !important;
}
.mt-3{
  margin-top: 2rem !important;
}
.mt-2{
  margin-top: 1.5rem !important;
}
.mt-1{
  margin-top: 1rem !important;
}

.mb-5{
  margin-bottom: 3rem !important;
}

.mb-4{
  margin-bottom: 2.5rem !important;
}
.mb-3{
  margin-bottom: 2rem !important;
}
.mb-2{
  margin-bottom: 1.5rem !important;
}
.mb-1{
  margin-bottom: 1rem !important;
}


.alert-danger{
  color: #ff8e8e !important;
}


.Loader{
  width: 100%;
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Loader h2{
  color: white;
}

.loader-circle {
  border: 16px solid #f2f2ff; /* Light grey */
  border-top: 16px solid #0187ff; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (max-width: 768px){
  p{
    font-size: 1rem;
  }
  h1, h2{
    font-size: 1.5rem;
    font-weight: 800;
    /* color: white; */
  }
  .container{
    width: 92%;
    margin-left: 4%;
  }
  .row{
    flex-direction: column;
  }
  .HomePage .col{
    flex-direction: column;
  }
}
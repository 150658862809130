.HomeLayout {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(17.83%, #0d41d5), to(#0187ff));
  background: -o-linear-gradient(top, #0d41d5 17.83%, #0187ff 100%);
  background: linear-gradient(180deg, #0d41d5 17.83%, #0187ff);
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.HomeLayout header {
  width: 100%;
  height: 100px;
  /* background: #1f45a3; */
  /* text-transform: lowercase; */
  /* color: #f2f2ff; */
  /* height: 100%; */
}
.HomeLayout header .container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.HomeLayout .page {
  padding-bottom: 40px;
}


.HomeLayout .ad-space a{
  width: 100%;
  display: flex;
  justify-content: center;
}



.HomeLayout footer {
  /* margin-top: 20px; */
  height: 90px;
  background: #f1f7fe;
}

.HomeLayout footer .container {
  height: 100%;
}

.HomeLayout footer ul {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 10px;
  list-style: none;
}

.HomeLayout footer li {
  color: #9193b5;
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: 400;
  box-sizing: border-box;
  padding-right: 10px;
}

.HomeLayout footer a {
  color: #9193b5;
  text-decoration: none;
  font-size: 0.9rem;
}


.HomeLayout footer li:not(:last-child) {
  border-right: 1px solid #9193b5;
}

/* .HomeLayout nav{
  color: #f2f2ff;
  text-transform: capitalize;
  text-decoration: none;
  width: 80%;
  margin-left: 10%;
}

.HomeLayout header ul{
  display: flex;
  justify-content: flex-start;
  list-style: none;
  gap: 20px;
} */

.HomeLayout header h1, .HomeLayout header p {
  color: rgb(255, 255, 255);
}

.HomeLayout header a {
  /* text-transform: capitalize; */
  text-decoration: none;
}

.LinkMaker #link_text {
  height: 300px;
  box-sizing: border-box;
  padding: 20px;
}

.link-maker-wrapper,
.file-upload-wrapper {
  width: 85%;
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  height: 100%;
  box-sizing: border-box;
}

.file-upload-wrapper {
  margin-left: 15%;
}

.link-maker-col,
.upload-file-col {
  width: 49.5%;
  word-wrap: break-word;
}



.UploadFile .headings,
.LinkMaker .headings {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  min-height: 110px;
}

.UploadFile .drop-zone {
  width: 100%;
  height: 300px;
  background: #f2f2ff;
  border: 1px solid #3e3e6c;
  color: #3e3e6c;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  padding: 20px;
  text-align: center;
  margin-top: 10px;
}

.UploadFile .upload-file {
  color: #3e3e6c;
}

.UploadFile .download-btn {
  width: 100%;
}

.drop-zone .select-file-btn {
  background: transparent;
  border: none;
  min-width: fit-content;
  min-height: fit-content;
}

.drop-zone .select-file-btn:hover {
  background: transparent !important;
}

.drop-zone img {
  width: 150px;
}


.TextViewer {
  width: 100%;
  background: white;
  box-sizing: border-box;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.TextViewer h1 {
  color: black;
}

.clipboard-copy {
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-radius: 20px;
  background: rgb(185, 194, 231);
}

.clipboard-copy p {
  overflow-wrap: break-word;
  word-break: break-all;
  padding: 15px;
}


.clipboard-copy button {
  background: transparent !important;
  border: none !important;
  min-width: fit-content;
  min-height: 100%;
  border-radius: 0 20px 20px 0;
}

.clipboard-copy img {
  width: 20px;
  cursor: pointer;
}

/* .UploadFile .join-someone-room, .UploadFile .create-room{
  margin-top: 50px;  
} */

.Ads {
  background: rgb(185, 194, 231);
  width: 100%;
  height: 200px;
}


.ReceiveFilePage .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ReceiveFilePage p,
.ReceiveFilePage h1 {
  color: white;
}

.terms-text {
  background-color: #ffffff;
}

.terms-text p,
li {
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 1.4;
}

.terms-text h1 {
  font-size: 1.5rem;
  font-weight: 800;
  padding: 40px 0;
}

.terms-text h2 {
  font-size: 1.4rem;
  font-weight: 700;
  padding: 20px 0;
}

.terms-text .list-level-2 {
  margin-left: 5%;
}

.terms-text .list-level-1 li {
  margin-left: 0;
  margin-bottom: 10px;
}








@media (max-width: 1200px) {
  .HomeLayout header h1 {
    font-size: 1.5rem;
  }
}

@media (max-width: 768px) {

  .HomeLayout .page {
    padding-bottom: 10px;
  }

  /* .HomeLayout footer{
    margin-top: 40px;
  } */
  .file-upload-wrapper {
    margin-left: 0;
    margin-top: 1rem;
  }

  .link-maker-col,
  .upload-file-col {
    width: 100%;
  }

  .link-maker-wrapper,
  .file-upload-wrapper {
    height: fit-content;
    width: 100%;
    margin-left: 0;
  }

  .HomeLayout .ad-space .banner{
    width: 100%;
  }


  .HomeLayout footer li {
    height: 50%;
  }

  .terms-text .list-level-1 li {
    margin-left: 5%;
  }

  .terms-text .list-level-2 {
    margin-left: 0;
  }
}
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;500;600&display=swap);
/* https://css-tricks.com/almanac/properties/a/animation/ */
/* Animations  */
@-webkit-keyframes btn_color_out {
  0% {
    color: white;
    background: #0187ff;
  }
  100% {
    color: #f2f2ff;
    background: #1f45a3;
  }
}
@keyframes btn_color_out {
  0% {
    color: white;
    background: #0187ff;
  }
  100% {
    color: #f2f2ff;
    background: #1f45a3;
  }
}


*{
  padding: 0;
  margin: 0;
}




/* Remove Arrows/Spinners */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}

body {
  /* font-family: 'Montserrat', sans-serif; */
  font-family: 'Arial Black', Gadget, sans-serif;
  font-weight: 400;  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



button{
  border: none;
  padding: 10px 15px;
  outline: none;
  border-radius: 32px;
  min-height: 50px;
  min-width: 250px;
  /* - button height 50px */
  font-size: 1.4rem;
  font-weight: 700;
}


input, textarea{
  /* padding: 13px; */
  /* border-radius: 5px; */
  /* border: 2px solid #1f45a3; */
  background-color: #f2f2ff;
  /* color: #1f45a3; */
  outline: none;
}
input{
  min-height: 40px;
}
p{
  font-size: 1.4rem;
}
p span{
  color: #ffc727;
}
h1{
  font-size: 2.2rem;
  font-weight: 800;
}
h2{
  font-size: 2.2rem;
}




label{
  margin-bottom: 5px;
  font-size: 1.4rem;
}



.container{
  width: 80%;
  margin-left: 10%;
}

.row{
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.btn{
  color: #f2f2ff;
  /* background: linear-gradient(180deg,#0d41d5 17.83%,#0187ff); */
  background: #0187ff;
  
}

.btn:hover{
  /* background: linear-gradient(180deg,#0d41d5 17.83%,#0187ff); */
  /* color: #1f45a3; */
  border: 2px solid #1f45a3;
  -webkit-animation: btn_color_out 2s infinite;
          animation: btn_color_out 2s infinite;
}

.btn-outline{
  color:#1f45a3;
  /* background: linear-gradient(180deg,#0d41d5 17.83%,#0187ff); */
  background: #f2f2ff;
}

.btn-outline:hover{
  background:#1f45a3;
  /* background: linear-gradient(180deg,#0d41d5 17.83%,#0187ff); */
  color: #f2f2ff;
  /* animation: name duration timing-function delay iteration-count direction fill-mode; */
}


.input-group{
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 6px;
  gap: 6px;
  margin-top: 10px;
}

.form-control{
  /* width: 100%; */
  padding: 5px;
}


.mt-5{
  margin-top: 3rem !important;
}

.mt-4{
  margin-top: 2.5rem !important;
}
.mt-3{
  margin-top: 2rem !important;
}
.mt-2{
  margin-top: 1.5rem !important;
}
.mt-1{
  margin-top: 1rem !important;
}

.mb-5{
  margin-bottom: 3rem !important;
}

.mb-4{
  margin-bottom: 2.5rem !important;
}
.mb-3{
  margin-bottom: 2rem !important;
}
.mb-2{
  margin-bottom: 1.5rem !important;
}
.mb-1{
  margin-bottom: 1rem !important;
}


.alert-danger{
  color: #ff8e8e !important;
}


.Loader{
  width: 100%;
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Loader h2{
  color: white;
}

.loader-circle {
  border: 16px solid #f2f2ff; /* Light grey */
  border-top: 16px solid #0187ff; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
          animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@media (max-width: 768px){
  p{
    font-size: 1rem;
  }
  h1, h2{
    font-size: 1.5rem;
    font-weight: 800;
    /* color: white; */
  }
  .container{
    width: 92%;
    margin-left: 4%;
  }
  .row{
    flex-direction: column;
  }
  .HomePage .col{
    flex-direction: column;
  }
}
.HomeLayout {
  background: linear-gradient(180deg, #0d41d5 17.83%, #0187ff);
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.HomeLayout header {
  width: 100%;
  height: 100px;
  /* background: #1f45a3; */
  /* text-transform: lowercase; */
  /* color: #f2f2ff; */
  /* height: 100%; */
}
.HomeLayout header .container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.HomeLayout .page {
  padding-bottom: 40px;
}


.HomeLayout .ad-space a{
  width: 100%;
  display: flex;
  justify-content: center;
}



.HomeLayout footer {
  /* margin-top: 20px; */
  height: 90px;
  background: #f1f7fe;
}

.HomeLayout footer .container {
  height: 100%;
}

.HomeLayout footer ul {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  grid-gap: 10px;
  gap: 10px;
  list-style: none;
}

.HomeLayout footer li {
  color: #9193b5;
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: 400;
  box-sizing: border-box;
  padding-right: 10px;
}

.HomeLayout footer a {
  color: #9193b5;
  text-decoration: none;
  font-size: 0.9rem;
}


.HomeLayout footer li:not(:last-child) {
  border-right: 1px solid #9193b5;
}

/* .HomeLayout nav{
  color: #f2f2ff;
  text-transform: capitalize;
  text-decoration: none;
  width: 80%;
  margin-left: 10%;
}

.HomeLayout header ul{
  display: flex;
  justify-content: flex-start;
  list-style: none;
  gap: 20px;
} */

.HomeLayout header h1, .HomeLayout header p {
  color: rgb(255, 255, 255);
}

.HomeLayout header a {
  /* text-transform: capitalize; */
  text-decoration: none;
}

.LinkMaker #link_text {
  height: 300px;
  box-sizing: border-box;
  padding: 20px;
}

.link-maker-wrapper,
.file-upload-wrapper {
  width: 85%;
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  height: 100%;
  box-sizing: border-box;
}

.file-upload-wrapper {
  margin-left: 15%;
}

.link-maker-col,
.upload-file-col {
  width: 49.5%;
  word-wrap: break-word;
}



.UploadFile .headings,
.LinkMaker .headings {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  min-height: 110px;
}

.UploadFile .drop-zone {
  width: 100%;
  height: 300px;
  background: #f2f2ff;
  border: 1px solid #3e3e6c;
  color: #3e3e6c;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  padding: 20px;
  text-align: center;
  margin-top: 10px;
}

.UploadFile .upload-file {
  color: #3e3e6c;
}

.UploadFile .download-btn {
  width: 100%;
}

.drop-zone .select-file-btn {
  background: transparent;
  border: none;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
}

.drop-zone .select-file-btn:hover {
  background: transparent !important;
}

.drop-zone img {
  width: 150px;
}


.TextViewer {
  width: 100%;
  background: white;
  box-sizing: border-box;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.TextViewer h1 {
  color: black;
}

.clipboard-copy {
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-radius: 20px;
  background: rgb(185, 194, 231);
}

.clipboard-copy p {
  overflow-wrap: break-word;
  word-break: break-all;
  padding: 15px;
}


.clipboard-copy button {
  background: transparent !important;
  border: none !important;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  min-height: 100%;
  border-radius: 0 20px 20px 0;
}

.clipboard-copy img {
  width: 20px;
  cursor: pointer;
}

/* .UploadFile .join-someone-room, .UploadFile .create-room{
  margin-top: 50px;  
} */

.Ads {
  background: rgb(185, 194, 231);
  width: 100%;
  height: 200px;
}


.ReceiveFilePage .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ReceiveFilePage p,
.ReceiveFilePage h1 {
  color: white;
}

.terms-text {
  background-color: #ffffff;
}

.terms-text p,
li {
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 1.4;
}

.terms-text h1 {
  font-size: 1.5rem;
  font-weight: 800;
  padding: 40px 0;
}

.terms-text h2 {
  font-size: 1.4rem;
  font-weight: 700;
  padding: 20px 0;
}

.terms-text .list-level-2 {
  margin-left: 5%;
}

.terms-text .list-level-1 li {
  margin-left: 0;
  margin-bottom: 10px;
}








@media (max-width: 1200px) {
  .HomeLayout header h1 {
    font-size: 1.5rem;
  }
}

@media (max-width: 768px) {

  .HomeLayout .page {
    padding-bottom: 10px;
  }

  /* .HomeLayout footer{
    margin-top: 40px;
  } */
  .file-upload-wrapper {
    margin-left: 0;
    margin-top: 1rem;
  }

  .link-maker-col,
  .upload-file-col {
    width: 100%;
  }

  .link-maker-wrapper,
  .file-upload-wrapper {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    margin-left: 0;
  }

  .HomeLayout .ad-space .banner{
    width: 100%;
  }


  .HomeLayout footer li {
    height: 50%;
  }

  .terms-text .list-level-1 li {
    margin-left: 5%;
  }

  .terms-text .list-level-2 {
    margin-left: 0;
  }
}
